<template>
  <!-- <div class="h-100"> -->
  <b-form-group
    class="wameed-input text-bold-14 text-font-main"
    :class="fieldClasses"
    :label-for="id"
  >
    <label
      v-if="label"
      :class="rules && rules.split('|').includes('required') ? 'required' : ''"
      class="text-font-main"
      :for="id"
      >{{ label }}</label
    >
    <span v-if="subLable" class="text-bold-14 text-icon">{{ subLable }}</span>
    <validation-provider
      #default="{ errors }"
      :name="name"
      :vid="name"
      :rules="rules"
    >
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <v-select
          :value="value"
          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
          :label="title"
          :multiple="multiple"
          :state="errors.length > 0 ? false : null"
          :name="id"
          :class="classes"
          :options="items"
          :placeholder="placeholder"
          :clearable="clearable"
          :close-on-select="!multiple"
          :deselect-from-dropdown="true"
          :searchable="search ? true : false"
          :disabled="disabled"
          :taggable="search ? false : true"
          append-to-body
          :calculate-position="withPopper"
          @input="updateValue($event)"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              {{ $t('common.no_results_found') }} <em>{{ search }}</em
              >.
            </template>
          </template>
          <template #selected-option-container="{ option, deselect }">
            <div
              v-if="
                multiple &&
                value &&
                value.findIndex(
                  (item) => item[identifier] == option[identifier]
                ) < 2
              "
              class="vs__selected text-bold-14"
            >
              {{ translate ? $t(translate + option[title]) : option[title]
              }}<close-select-icon @click="deselect(option)" />
            </div>
            <div
              v-if="
                multiple &&
                value &&
                value.findIndex(
                  (item) => item[identifier] == option[identifier]
                ) == 2
              "
              class="vs__selected text-bold-14"
            >
              {{ '+' + (value.length - 2) }}
            </div>

            <div v-if="title && !multiple" class="vs__selected">
              {{ translate ? $t(translate + option[title]) : option[title] }}
            </div>
            <div v-if="listTitle && !multiple" class="vs__selected">
              <span v-for="(item, index) in listTitle" :key="index">
                <span class="px-1">{{ item.separator }}</span>
                <span class="px-1">{{ option[item.name] }}</span>
              </span>
            </div>
          </template>
          <template v-slot:option="option">
            <b-form-checkbox
              v-if="multiple"
              class="custom-control-main"
              :class="
                value &&
                value.findIndex(
                  (item) => item[identifier] == option[identifier]
                ) > -1
                  ? 'active'
                  : ''
              "
              :checked="
                value &&
                value.findIndex(
                  (item) => item[identifier] == option[identifier]
                ) > -1
              "
            >
              <span class="text-bold-14 text-font-sub mx-2">{{
                translate ? $t(translate + option[title]) : option[title]
              }}</span>
            </b-form-checkbox>

            <span
              v-if="title && !multiple"
              class="text-bold-14 text-font-sub mx-2"
              >{{
                translate ? $t(translate + option[title]) : option[title]
              }}</span
            >
            <template v-if="listTitle && !multiple">
              <span
                v-for="(item, index) in listTitle"
                :key="index"
                class="text-bold-14 text-font-sub mx-0"
                dir="rtl"
              >
                <span class="px-1">{{ item.separator }}</span>
                <span class="px-1">{{ option[item.name] }}</span>
              </span>
            </template>
          </template>
        </v-select>
      </b-input-group>

      <small class="text-danger">{{ errors[0] }}</small>
      <small v-if="errorField" class="text-warning">
        {{ $t("common.please_edit") }} {{ label }}</small
      >
    </validation-provider>
  </b-form-group>
  <!-- </div> -->
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { createPopper } from '@popperjs/core';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },

    translate: {
      type: String,
      default: null,
    },
    subLable: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    listTitle: {
      type: Array,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom',
    },
    variant: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    activeItem: {
      type: String,
      default: null,
    },
    valueClasses: {
      type: String,
      default: null,
    },
    value: {
      default: null,
    },
    fieldClasses: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    identifier: {
      type: String,
      default: 'id',
    },
    errorField: {
      type: Boolean,
      default: null,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dropdownClick(item) {
      this.$emit('dropdownClick', item);
    },
    updateValue(value) {
      this.$emit('input', value);
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
  },
};
</script>
