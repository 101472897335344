var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{staticClass:"wameed-input text-bold-14 text-font-main",class:_vm.fieldClasses,attrs:{"label-for":_vm.id}},[(_vm.label)?_c('label',{staticClass:"text-font-main",class:_vm.rules && _vm.rules.split('|').includes('required') ? 'required' : '',attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.subLable)?_c('span',{staticClass:"text-bold-14 text-icon"},[_vm._v(_vm._s(_vm.subLable))]):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.name,"vid":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('v-select',{class:_vm.classes,attrs:{"value":_vm.value,"dir":_vm.$i18n.locale == 'ar' ? 'rtl' : 'ltr',"label":_vm.title,"multiple":_vm.multiple,"state":errors.length > 0 ? false : null,"name":_vm.id,"options":_vm.items,"placeholder":_vm.placeholder,"clearable":_vm.clearable,"close-on-select":!_vm.multiple,"deselect-from-dropdown":true,"searchable":_vm.search ? true : false,"disabled":_vm.disabled,"taggable":_vm.search ? false : true,"append-to-body":"","calculate-position":_vm.withPopper},on:{"input":function($event){return _vm.updateValue($event)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('common.no_results_found'))+" "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}},{key:"selected-option-container",fn:function(ref){
var option = ref.option;
var deselect = ref.deselect;
return [(
              _vm.multiple &&
              _vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) < 2
            )?_c('div',{staticClass:"vs__selected text-bold-14"},[_vm._v(" "+_vm._s(_vm.translate ? _vm.$t(_vm.translate + option[_vm.title]) : option[_vm.title])),_c('close-select-icon',{on:{"click":function($event){return deselect(option)}}})],1):_vm._e(),(
              _vm.multiple &&
              _vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) == 2
            )?_c('div',{staticClass:"vs__selected text-bold-14"},[_vm._v(" "+_vm._s('+' + (_vm.value.length - 2))+" ")]):_vm._e(),(_vm.title && !_vm.multiple)?_c('div',{staticClass:"vs__selected"},[_vm._v(" "+_vm._s(_vm.translate ? _vm.$t(_vm.translate + option[_vm.title]) : option[_vm.title])+" ")]):_vm._e(),(_vm.listTitle && !_vm.multiple)?_c('div',{staticClass:"vs__selected"},_vm._l((_vm.listTitle),function(item,index){return _c('span',{key:index},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(item.separator))]),_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(option[item.name]))])])}),0):_vm._e()]}},{key:"option",fn:function(option){return [(_vm.multiple)?_c('b-form-checkbox',{staticClass:"custom-control-main",class:_vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) > -1
                ? 'active'
                : '',attrs:{"checked":_vm.value &&
              _vm.value.findIndex(
                function (item) { return item[_vm.identifier] == option[_vm.identifier]; }
              ) > -1}},[_c('span',{staticClass:"text-bold-14 text-font-sub mx-2"},[_vm._v(_vm._s(_vm.translate ? _vm.$t(_vm.translate + option[_vm.title]) : option[_vm.title]))])]):_vm._e(),(_vm.title && !_vm.multiple)?_c('span',{staticClass:"text-bold-14 text-font-sub mx-2"},[_vm._v(_vm._s(_vm.translate ? _vm.$t(_vm.translate + option[_vm.title]) : option[_vm.title]))]):_vm._e(),(_vm.listTitle && !_vm.multiple)?_vm._l((_vm.listTitle),function(item,index){return _c('span',{key:index,staticClass:"text-bold-14 text-font-sub mx-0",attrs:{"dir":"rtl"}},[_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(item.separator))]),_c('span',{staticClass:"px-1"},[_vm._v(_vm._s(option[item.name]))])])}):_vm._e()]}}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.errorField)?_c('small',{staticClass:"text-warning"},[_vm._v(" "+_vm._s(_vm.$t("common.please_edit"))+" "+_vm._s(_vm.label))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }